import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllPortfolioHandler,
  deletePortfolioHandler,
  addPortfolioHandler,
  updatePortfolioHandler,
} from "./uploadportfolio.thunk";

const INIT_STATE = {
  uploadportfoliodata: [],
  isLoading: false,
};

export const fetchAllUploadPortfolioThunk = createAsyncThunk(
  "fetchAllUploadPortfolio",
  fetchAllPortfolioHandler
);

export const deleteUploadPortfolioThunk = createAsyncThunk(
  "deleteUploadPortfolio",
  deletePortfolioHandler
);

export const addUploadPortfolioThunk = createAsyncThunk(
  "addUploadPortfolio",
  addPortfolioHandler
);

export const updateUploadPortfolioThunk = createAsyncThunk(
  "updateUploadPortfolio",
  updatePortfolioHandler
);

const uploadportfolioSlice = createSlice({
  name: "uploadportfolio",
  initialState: INIT_STATE,
  reducers: {
    startUploadPortfolioLoader: (state) => {
      state.isLoading = true;
    },
    stopUploadPortfolioLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUploadPortfolioThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllUploadPortfolioThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.uploadportfoliodata = action.payload;
      })
      .addCase(fetchAllUploadPortfolioThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addUploadPortfolioThunk.fulfilled, (state, action) => {
        state.uploadportfoliodata = [
          ...state.uploadportfoliodata,
          ...action.payload,
        ];
        return state;
      });
  },
});

export const { startUploadPortfolioLoader, stopUploadPortfolioLoader } =
  uploadportfolioSlice.actions;

export default uploadportfolioSlice.reducer;
