import {
  getAllExpertise,
  updateDeleteAddExpertise,
} from "../../../utils/apis.utils";
import {
  startExpertiseLoader,
  stopExpertiseLoader,
} from "../expertisebreakup/expertisebreakup.slice";

import { fetchAllExpertiseThunk } from "./expertisebreakup.slice";

import { showFailureToast, showSuccessToast } from "../toast/toast.slice";
import { createQueryString } from "../../../utils/global/global";

export const fetchAllExpertiseHandler = async (page = 1, thunkApi) => {
  const query = createQueryString({
    page,
  });
  thunkApi.dispatch(startExpertiseLoader());
  const { vendor } = thunkApi.extra.apiService;
  const response = await vendor.get(getAllExpertise + query);
  thunkApi.dispatch(stopExpertiseLoader());
  return {
    data: response.data,
    total: response.total,
  };
  // return response.data;
};

export const deleteExpertiseHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startExpertiseLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.delete(
      updateDeleteAddExpertise + "/" + data.id
    );
    thunkApi.dispatch(stopExpertiseLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    thunkApi.dispatch(fetchAllExpertiseThunk(data.page));
    // return id;
  } catch (err) {
    thunkApi.dispatch(stopExpertiseLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopExpertiseLoader());
  }
};

export const addExpertiseHandler = async (data, thunkApi) => {
  try {
    thunkApi.dispatch(startExpertiseLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.post(updateDeleteAddExpertise, data.data);
    thunkApi.dispatch(stopExpertiseLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    // return response.data;
    thunkApi.dispatch(fetchAllExpertiseThunk(data.page));
  } catch (err) {
    thunkApi.dispatch(stopExpertiseLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopExpertiseLoader());
  }
};

export const updateExpertiseHandler = async (data, thunkApi) => {
  var id = data.id;
  var apiData = {
    data: {
      tech_stack: {
        tech_stack_id: data.tech_stack.tech_stack_id,
        name: data.tech_stack.name,
      },
      employees_1_3: parseInt(data.employees_1_3),
      employees_4_8: parseInt(data.employees_4_8),
      employees_8_more: parseInt(data.employees_8_more),
    },
  };

  try {
    thunkApi.dispatch(startExpertiseLoader());
    const { vendor } = thunkApi.extra.apiService;
    const response = await vendor.put(
      updateDeleteAddExpertise + "/" + id,
      apiData
    );
    thunkApi.dispatch(stopExpertiseLoader());
    thunkApi.dispatch(
      showSuccessToast({
        message: response.message,
        vertical: "top",
        horizontal: "right",
      })
    );
    return response.data;
  } catch (err) {
    thunkApi.dispatch(stopExpertiseLoader());
    thunkApi.dispatch(showFailureToast({ message: err }));
    return Promise.reject(err);
  } finally {
    thunkApi.dispatch(stopExpertiseLoader());
  }
};
