import PropTypes from "prop-types";
// material
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      // src="https://emb-website.s3.ap-south-1.amazonaws.com/logo.svg"
      src="https://cdn.prod.website-files.com/6594b3bb27ede6e1104d3b10/6602f085bc553b4527ab1458_Ground.webp"
      sx={{ width: 150, display: "block", ...sx }}
    />
  );
}
