export const changeStringToNum = (data, value) => {
  const respWithNumberCount = data?.map((obj) => {
    return { ...obj, [value]: parseInt(obj?.[value]) };
  });
  return respWithNumberCount;
};

export const addNullId = (data) => {
  return data.map((obj) => {
    if (obj.id) return obj;
    return { ...obj, id: null };
  });
};
