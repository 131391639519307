import React from "react";
import { Box, Grid, Button } from "@mui/material";
import { addressInfovalidation } from "../../utils/validations/vendor.validation";
import useForm from "../../hooks/useForm";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { useDispatch } from "react-redux";
import {
  addAddressForBusinessProfileThunk,
  fetchVendorProfileDetailsThunk,
  updateBusinessAddressThunk,
} from "../../store/slices/vendor/vendor.slice";
import useBusinessId from "../../hooks/useBusinessId";
import { addressTypes } from "../../utils/options.util";

const INIT_STATE = {
  address_line1: "",
  address_line2: "",
  city: "",
  state: "",
  country: "",
  zip_code: "",
  address_type: "",
  address_proof_file: "",
};

const AddressModal = ({ closeModal, data, page }) => {
  const businessId = useBusinessId();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useForm({
      initialValues: data ? data : INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: addressInfovalidation,
    });

  const dispatch = useDispatch();

  function onSubmit(values) {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    // if (values.address_type === "registered") {
    //   formData.append("gst_or_tin_verified_address", true);
    // }
    formData.append("gst_or_tin_verified_address", true);

    // console.log('formData', formData)
    if (data) {
      dispatch(updateBusinessAddressThunk({ data: formData, id: data.id }))
        .unwrap()
        .then(dispatch(fetchVendorProfileDetailsThunk()));
    } else {
      dispatch(addAddressForBusinessProfileThunk(formData))
        .unwrap()
        .then(dispatch(fetchVendorProfileDetailsThunk()));
    }
    closeModal();
  }
  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid item md={12} pb={0}>
            <Element
              label="Address Type"
              inputProps={{
                type: "text",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "address_type",
              }}
              eletype={inputType.select}
              options={addressTypes}
              value={values.address_type}
              errorText={touched.address_type && errors.address_type}
            />
          </Grid>

          <Grid item md={12} pb={0}>
            <Element
              label="Address Line1"
              inputProps={{
                type: "text",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "address_line1",
              }}
              eletype={inputType.input}
              value={values.address_line1}
              errorText={touched.address_line1 && errors.address_line1}
            />
          </Grid>
          <Grid item md={12} pb={0}>
            <Element
              label="Address Line2"
              inputProps={{
                type: "text",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "address_line2",
              }}
              eletype={inputType.input}
              value={values.address_line2}
              errorText={touched.address_line2 && errors.address_line2}
            />
          </Grid>
          <Grid item md={12} pb={0}>
            <Element
              label="City"
              inputProps={{
                type: "text",
                placeholder: "Enter City Name",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "city",
              }}
              eletype={inputType.input}
              value={values.city}
              errorText={touched.city && errors.city}
            />
          </Grid>
          <Grid item md={12} pb={0}>
            <Element
              label="State"
              inputProps={{
                type: "text",
                placeholder: "Enter State Name",
                onChange: handleChange,
                onBlur: handleBlur,
                name: "state",
              }}
              eletype={inputType.input}
              value={values.state}
              errorText={touched.state && errors.state}
            />
            <Grid item md={12} pb={0}>
              <Element
                label="Country"
                inputProps={{
                  type: "text",
                  placeholder: "Enter Country Name",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "country",
                }}
                eletype={inputType.input}
                value={values.country}
                errorText={touched.country && errors.country}
              />
            </Grid>
            <Grid item md={12} pb={0}>
              <Element
                label="Zip Code"
                inputProps={{
                  type: "text",
                  placeholder: "Enter Pincode",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "zip_code",
                }}
                eletype={inputType.input}
                value={values.zip_code}
                errorText={touched.zip_code && errors.zip_code}
              />
            </Grid>

            <Grid item md={12}>
              <Element
                eletype={inputType.file}
                label="Address Proof"
                inputProps={{
                  name: "address_proof_file",
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                value={values.address_proof_file}
                fileUploadWithoutUrl={true}
              />
            </Grid>

            <Grid item md={12} py={2}>
              <Button size="large" type="submit" variant="contained">
                Add Address
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default AddressModal;
