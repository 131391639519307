import { useEffect, useState } from "react";
import {
  fetchAllDeliveryClass,
  fetchAllIndustries,
  fetchAllServices,
} from "../utils/server.util";

function useProductCodeData() { 
  const [services, setServices] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [deliveryClass, setDeliveryClass] = useState([]);


  useEffect(() => {
    (async function () {
      const services = await fetchAllServices();
      const industries = await fetchAllIndustries();
      const deliveryClass = await fetchAllDeliveryClass();

      setServices(
        services.map((ele) => ({
          label: ele.title,
          value: ele._id,
        }))
      );
      setIndustries(
        industries.map((ele) => ({
          label: ele.title,
          value: ele._id,
        }))
      );
      setDeliveryClass(
        deliveryClass.map((ele) => ({
          label: ele.name,
          value: ele._id,
        }))
      );
    })();
  }, []);

  return {
    services,
    industries,
    deliveryClass,
  };
}

export default useProductCodeData;
