import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  main_list: {
    listStyle: "none",
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      backgroundColor: "#eff8f1",
      borderColor: "#00aa55",
    },
  },
  list: {
    display: "flex",
    marginBottom: "0.5rem",
  },
  nest_list: {
    display: "flex",
    marginBottom: "0.5rem",
    gap: "5px",
  },
  nest_list_1: {
    display: "flex",
    justifyContent: "center",
    listStyle: "none",
  },
  nest_list_1_li: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    display: "flex",
    gap: "5px",
    cursor: "pointer",
    color: "#6a7986",
    padding: "1rem 0.3rem",
    paddingLeft: "0.5rem",
    borderRadius: "5px",
  },
});
