import { useEffect, useState } from "react";
import { getResouceBifercationList } from "../utils/server.util";
import { capitalCase } from "change-case";

function useResourceBifercation() {
  const [resourceBifercation, setResourceBifercation] = useState([]);

  useEffect(() => {
    (async function () {
      const response = await getResouceBifercationList();
      setResourceBifercation(
        response.map((ele) => ({
          label: capitalCase(ele.name),
          value: ele._id,
        }))
      );
    })();
  }, []);

  function getObj(id) {
    const obj = resourceBifercation.find((ele) => ele.value === id);
    return {
      id: obj?.value,
      name: obj?.label,
    };
  }

  return [resourceBifercation, getObj];
}

export default useResourceBifercation;
