import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  googleLoginButton: {
    width: "100%",
    height: "50px",
    color: "black",
    display: "flex !important",
    justifyContent: "center",
    boxShadow: "none !important",
    border: "1px solid rgba(0, 0, 0, .125) !important",
  },
});
