import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllExpertiseHandler,
  deleteExpertiseHandler,
  addExpertiseHandler,
  updateExpertiseHandler,
} from "./expertisebreakup.thunk";

const INIT_STATE = {
  expertisedata: [],
  isLoading: false,
  totalexpertise: 0,
};

export const fetchAllExpertiseThunk = createAsyncThunk(
  "fetchAllExpertise",
  fetchAllExpertiseHandler
);

export const deleteExpertiseThunk = createAsyncThunk(
  "deleteExpertise",
  deleteExpertiseHandler
);

export const AddExpertiseThunk = createAsyncThunk(
  "addExpertise",
  addExpertiseHandler
);

export const updateExpertiseThunk = createAsyncThunk(
  "updateExpertise",
  updateExpertiseHandler
);

const expertiseSlice = createSlice({
  name: "expertise",
  initialState: INIT_STATE,
  reducers: {
    startExpertiseLoader: (state) => {
      state.isLoading = true;
    },
    stopExpertiseLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllExpertiseThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllExpertiseThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.expertisedata = action.payload.data;
        state.totalexpertise = action.payload.total;
      })
      .addCase(fetchAllExpertiseThunk.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(AddExpertiseThunk.fulfilled, (state, action) => {
        // state.expertisedata.push(action.payload);
        // state.totalexpertise = state.totalexpertise + 1;
      })
      .addCase(deleteExpertiseThunk.fulfilled, (state, action) => {
        // var id = action.payload;
        // var data = state.expertisedata.filter((ele) => ele.id !== id);
        // state.expertisedata = data;
        // state.totalexpertise = state.totalexpertise - 1;
        // return state;
      })
      .addCase(updateExpertiseThunk.fulfilled, (state, action) => {
        const idx = state.expertisedata.findIndex(
          (ele) => ele.id === action.payload.id
        );
        state.expertisedata[idx] = action.payload;
        return state;
      });
  },
});

export const { startExpertiseLoader, stopExpertiseLoader } =
  expertiseSlice.actions;

export default expertiseSlice.reducer;
