import { useEffect, useState } from "react";
import { getTechStackList } from "../utils/server.util";
import { capitalCase } from "change-case";

function useTeckStack(id) {
  const [techStack, setTechStack] = useState([]);

  useEffect(() => {
    if (id) {
      fetchTechStack(id);
    }
  }, [id]);

  async function fetchTechStack(id) {
    const response = await getTechStackList(id);
    setTechStack(
      response.map((ele) => ({
        label: ele.name,
        value: ele._id,
      }))
    );
  }

  function getTechStackObj(id) {
    const obj = techStack.find((ele) => ele.value === id);
    return {
      id: obj?.value,
      name: obj?.label,
    };
  }

  return [techStack, fetchTechStack, getTechStackObj];
}

export default useTeckStack;
