import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { inputType } from "../../utils/enum";
import Element from "./Element";

function Address({ handleChange, handleBlur, values }) {
  return (
    <>
      <Typography gutterBottom variant="h5" component="div" mt={2}>
        Address Details
      </Typography>

      <Box sx={{ margin: "30px 0 10px 0" }}>
        <Element
          eletype={inputType.input}
          label="Address 1"
          inputProps={{
            name: "address.address_line1",
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          value={values.address_line1}
        />
      </Box>
      <Box sx={{ margin: "30px 0 10px 0" }}>
        <Element
          eletype={inputType.input}
          label="Address 2"
          inputProps={{
            name: "address.address_line2",
            onChange: handleChange,
            onBlur: handleBlur,
          }}
          value={values.address_line2}
        />
      </Box>
      <Box sx={{ margin: "20px 0 10px 0" }}>
        <Stack direction="row" justifyContent="space-evenly" spacing={2}>
          <Element
            eletype={inputType.input}
            label="Country"
            inputProps={{
              name: "address.country",
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            value={values.country}
          />

          <Element
            eletype={inputType.input}
            label="State/Region"
            inputProps={{
              name: "address.state",
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            value={values.state}
          />
        </Stack>
      </Box>
      <Box sx={{ margin: "20px 0 10px 0" }}>
        <Stack direction="row" justifyContent="space-evenly" spacing={2}>
          <Element
            eletype={inputType.input}
            label="City"
            inputProps={{
              name: "address.city",
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            value={values.city}
          />

          <Element
            eletype={inputType.input}
            label="Zip Code"
            inputProps={{
              name: "address.zip_code",
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            value={values.zip_code}
          />
        </Stack>
      </Box>
    </>
  );
}

export default Address;
