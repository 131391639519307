import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useStyles } from "../Form/Form.theme";
import useMobile from "../../hooks/useMobile";

function MobileInput({ label, errorText, value, name, onChange, onBlur,disabled }) {
  const classes = useStyles();

  const { handleChange, handleBlur, handleKeyDown } = useMobile({
    name,
    onChange,
    onBlur,
    value,
  });

  let computedValue;
  if (!value?.country_code || !value?.mobile_number) {
    computedValue = "+91";
  } else {
    computedValue = value?.country_code + " " + value?.mobile_number;
  }

  return (
    <div className={classes.formGroup}>
      {label ? <label className={classes.formLabel}>{label}</label> : null}
      <PhoneInput
        containerClass={classes.formControl}
        preferredCountries={["in", "us"]}
        inputStyle={{
          width: "100%",
          height: "100%",
        }}
        containerStyle={{ padding: "0px", border: "none" }}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        value={computedValue}
        inputProps={{
          onKeyDown: handleKeyDown,
        }}
        placeholder="Enter Mobile"
      />
      {errorText && <span className={classes.formErrorStyle}>{errorText}</span>}
    </div>
  );
}

export default MobileInput;
