import React, { useEffect, useState } from "react";
import { Box, Grid, Button } from "@mui/material";
import Element from "../../components/Form/Element";
import { inputType } from "../../utils/enum";
import { createProductCodeThunk } from "../../store/slices/vendorServices/vendorservice.slice";
import { useDispatch } from "react-redux";
import SearchService from "./SearchService";
import {
  searchProductCodeIndustryApi,
  searchServiceApi,
} from "../../utils/apis.utils";
import {
  deliveryClasskHandler,
  deliveryclassFormatter,
  newSearchIndustryHandler,
  newSearchServiceHandler,
  serviceFormatter,
} from "../../utils/global/vendor.global";
import { useFormik } from "formik";
import SingleSelectSearchIndustry from "./singleSelectSearchIndustry";
import SearchIndustryWithChild from "./SearchIndustrywithChild";
import { pastWorkValidation } from "../../utils/validations/vendor.validation";
import useProductCodeData from "../../hooks/useProductCodeData";
import { fetchSubIndustry } from "../../utils/server.util";

const INIT_STATE = {
  minimum_price: "",
  service: [],
  industry: [],
  sub_industry: [],
  delivery_class: [],
};

const RoleModal = ({ fncHandleClose, custom, data: initData, page }) => {
  const { minimum_price, service, industry, sub_industry, delivery_class } =
    initData ?? {};

  const { services, industries, deliveryClass } = useProductCodeData();

  const [subIndustriesList, setSubIndutries] = useState([]);

  // console.log(services, industries, deliveryClass, subIndustriesList);

  const dispatch = useDispatch();
  function onSubmit(data) {
    let serviceName = services.filter(
      (item) => item.value === (data.service.id || data.service)
    );
    let industryName = industries.filter(
      (item) => item.value === (data.industry.id || data.industry)
    );
    let subIndustryName = subIndustriesList[0].children.filter(
      (item) => item._id === (data.sub_industry.id || data.sub_industry)
    );
    let deliveryClassName = deliveryClass.filter(
      (item) => item.value === (data.delivery_class.id || data.delivery_class)
    );

    var new_data = {
      minimum_price: data?.minimum_price,
      service: {
        id: data.service.id ?? data.service,
        name: serviceName[0]?.label,
      },
      industry: {
        id: data.industry.id ?? data.industry,
        name: industryName[0]?.label,
      },
      sub_industry: {
        id: data.sub_industry.id ?? data.sub_industry,
        name: subIndustryName[0]?.title,
      },
      delivery_class: {
        id: data.delivery_class.id ?? data.delivery_class,
        name: deliveryClassName[0]?.label,
      },
    };

    delete data?.tags;
    delete data?.tech_stacks;
    if (initData) {
      return dispatch(
        createProductCodeThunk({
          id: initData.id,
          data: new_data,
        })
      )
        .unwrap()
        .then(fncHandleClose);
    }

    dispatch(createProductCodeThunk({ data: new_data }))
      .unwrap()
      .then(fncHandleClose);
  }

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: initData
        ? {
            minimum_price: minimum_price,
            service: service,
            industry: industry,
            sub_industry: sub_industry,
            delivery_class: delivery_class,
          }
        : INIT_STATE,
      onSubmit: onSubmit,
      validationSchema: pastWorkValidation,
    });

  console.log(initData, "initData");

  useEffect(async () => {
    if (values.industry.id) {
      // When values is already present (like, when clicked on edit)
      const subIndustriesList = await fetchSubIndustry(values.industry.id);
      setSubIndutries(subIndustriesList);
    } else if (values.industry) {
      const subIndustriesList = await fetchSubIndustry(values.industry);
      setSubIndutries(subIndustriesList);
    }
  }, [values.industry]);

  return (
    <>
      <Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={0} p={2}>
            {/* <Grid item md={12} pb={0}>
              <SearchService
                url={searchServiceApi}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Service"
                placeholder="Select Service"
                formatter={serviceFormatter}
                value={values.service}
                errorText={touched.service && errors.service}
                name="service"
                initTags={values.service}
                hideMoreInput={true}
                handler={newSearchServiceHandler}
              />
            </Grid> */}
            <Grid item md={12} pb={0}>
              <Element
                inputProps={{
                  name: "service",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  placeholder: "Select Service",
                }}
                label="Select Service"
                placeholder="Select Service"
                value={values.service}
                errorText={touched.service && errors.service}
                name="service"
                options={services}
                eletype={inputType.select}
              />
            </Grid>
            {/* <Grid item md={12} pb={0}>
              <SearchIndustryWithChild
                url={searchProductCodeIndustryApi}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Industry"
                placeholder="Select Industrty"
                formatter={serviceFormatter}
                value={values.industry}
                errorText={touched.industry && errors.industry}
                name="industry"
                initTechStackData={values.sub_industry}
                initTags={values.industry}
                handler={newSearchIndustryHandler}
              />
            </Grid> */}

            <Grid item md={12} pb={0}>
              <Element
                inputProps={{
                  name: "industry",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  placeholder: "Select Industry",
                }}
                label="Select Industry"
                placeholder="Select Industry"
                value={values.industry}
                errorText={touched.industry && errors.industry}
                name="service"
                options={industries}
                eletype={inputType.select}
              />
            </Grid>

            {subIndustriesList && (
              <Grid item md={12} pb={0}>
                <Element
                  inputProps={{
                    name: "sub_industry",
                    onChange: handleChange,
                    onBlur: handleBlur,
                    placeholder: "Select Sub Industry",
                  }}
                  label="Select Sub Industry"
                  placeholder="Select Sub Industry"
                  value={values.sub_industry}
                  errorText={touched.sub_industry && errors.sub_industry}
                  name="service"
                  options={subIndustriesList[0]?.children}
                  eletype={inputType.select}
                  resetTheField={values.industry.id ? false : true}
                />
              </Grid>
            )}

            {/* <Grid item md={12} pb={1}>
              <SingleSelectSearchIndustry
                name="delivery_class"
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Delivery Class"
                placeholder="Select Delivery Class"
                formatter={deliveryclassFormatter}
                value={values.delivery_class}
                errorText={touched.delivery_class && errors.delivery_class}
                handler={deliveryClasskHandler}
              />
            </Grid> */}

            <Grid item md={12} pb={0}>
              <Element
                inputProps={{
                  name: "delivery_class",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  placeholder: "Select Delivery Class",
                }}
                label="Select Delivery Class"
                placeholder="Select Delivery Class"
                value={values.delivery_class}
                errorText={touched.delivery_class && errors.delivery_class}
                name="delivery_class"
                options={deliveryClass}
                eletype={inputType.select}
              />
            </Grid>

            <Grid item md={12} pb={0}>
              <Element
                label="Minimum Price"
                inputProps={{
                  type: "text",
                  placeholder: "Enter Minimum Price",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  name: "minimum_price",
                }}
                eletype={inputType.number}
                value={values.minimum_price}
              />
            </Grid>

            <Grid item md={12} pb={2} justify="center">
              <Button size="large" type="submit" variant="contained">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default RoleModal;
